import { PageContextProps } from '@tb-core/components/context/webpage';
import { getServerSidePropsInit } from '@tb-core/next/server-side-props-hooks/get-page-props-init';
import HomePage from '@tb-public/components/container/home-page';

// Our GetServerSideProps page hook.
export const getServerSideProps = getServerSidePropsInit({
    pageSlug: '/'
});

// This is to allow for content author to test out multiple experiences of the homepage
const Home = (props: PageContextProps) => <HomePage {...props} />;

export default Home;
